<template>
	<ul class="companies-grid" v-if="!!$store.getters.getCompanies.length">
		<li v-for="(item, index) in $store.getters.getCompanies.slice(0, 8)" :key="index">
			<a v-if="!item.empty" :href="item.link">
				<span>{{ item.title }}</span>
				<div class="img-wrp">
					<img :src="item['simple_logo']" :alt="item.title"/>
				</div>
			</a>
		</li>
		<li class="all">
			<a href="/companies" class="txt">
				<b class="uppercase">Plat
					<wbr>
				                     forms </b>
				<img
						src="/template/img/icons/arrow-right.svg">
			</a>
		</li>
	</ul>
</template>

<script>
export default {
	name: "Companies",
	props: {},
	computed: {},
	data: () => {
		return {};
	},
	methods: {},
	created() {

	},
};
</script>
<style lang="scss">

</style>
