<template>
	<div class="loading">
		<div class="dot dot1"></div>
		<div class="dot dot2"></div>
		<div class="dot dot3"></div>
	</div>
</template>

<script>
export default {
	name: "loading"
}
</script>

<style scoped>

</style>