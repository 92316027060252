<script>
const checkbox = {
	mounted: (el, binding, values) => {
		let classes = el.classList,
				id = el.id,
				wrapper = document.createElement('div'),
				label = document.createElement('label');
		wrapper.classList = classes;
		wrapper.classList.add(['checkbox']);
		if (!(!!id)) {
			id = (Math.floor(Math.random() * 789));
			el.id = id;
		}
		label.setAttribute('for', id);
		el.parentNode.insertBefore(wrapper, el);
		wrapper.appendChild(el)
		wrapper.appendChild(label)
	}
}

export default checkbox
</script>