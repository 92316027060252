<template>
	<div class="hero-image go" :class="[{'loaded-image': imgLoaded}]" >
		<div class="img-card left">
			<img :src=" $store.getters.getPaths.media + '/img/media/hero-image.jpg'" alt="hero" ref="img">
		</div>
		<div class="img-card top">
			<img :src=" $store.getters.getPaths.media + '/img/media/hero-image.jpg'" alt="hero">
		</div>
		<div class="img-card right">
			<img :src=" $store.getters.getPaths.media + '/img/media/hero-image.jpg'" alt="hero">
		</div>
		<div class="img-card bottom">
			<img :src=" $store.getters.getPaths.media + '/img/media/hero-image.jpg'" alt="hero">
		</div>
	</div>
</template>

<script>
export default {
	name: 'hero-image',
	props: {},
	data: () => {
		return {
			img: null,
			imgLoaded: false
		}
	},
	methods: {
		loaded() {
			this.imgLoaded = true
		}
	},
	mounted() {
		this.img = this.$refs.img;
		this.img.addEventListener("load", this.loaded)
	}
}
</script>