<template>
	<div class="elma">
		<div class="elm -l"></div>
		<div class="elm -t"></div>
		<div class="elm -b"></div>
		<div class="elm -r"></div>
	</div>
</template>

<script>

export default {
	name: 'Elma',
	props: {}
}
</script>
