<template>
	<div class="slider back-navy color-beige" v-if="!!this.items.length">
		<div class="slider-texts-cont pad-all-72 relative">
			<div class="hidden-wrapper">
				<div class="slides-wrapper" ref="textWrapper"
				     :style="textWrapperStyle">
					<div class="slide-item-text" v-for="slide in items" :class="[{'active':slide.active}]">
						<h3 class="fs-40 fw-800 lh-10 mar-bot-48">
							<span class="number">{{ (slide.index + 1 < 10 ? "0" : "") + (slide.index + 1) }}</span>
							<b class="capital">{{ slide.title }}</b>
						</h3>
						<p class="color-white fs-20 lh-17 mar-bot-48">{{ slide.description }}</p>
						<div class="al-right relative z-9">
							<a class="btn outline beige" :href="slide.link">learn more</a>
						</div>
					</div>
				</div>
			</div>
			<div class="current-slide fs-20 fw-600 ">
				<span class="mar-all-1">{{ whoIsActive[0].index + 1 }}</span>
				<span class="mar-all-1">/</span>
				<span class="mar-all-1">{{ slides.length }}</span>
			</div>
		</div>
		<div class="slider-images-cont relative">
			<div class="images-wrapper" ref="imageWrapper"
			     :style="imageWrapperStyle">
				<div class="slide-item-img" v-for="slide in items" :class="[{'active':slide.active}]">
					<img :src="slide.image">
				</div>
			</div>
			<div class="arrows back-navy">
				<div class="arrow left" @click="slide('left')">
					<img src="/template/img/icons/slider-arrow-left.svg">
				</div>
				<div class="arrow right" @click="slide('right')">
					<img src="/template/img/icons/slider-arrow-right.svg">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'slider',
	props: {
		slides: []
	},
	computed: {
		whoIsActive() {
			let search = this.items.filter(item => item.active);
			return search;
		},
	},
	data: () => {
		return {
			items: [],
			textWrapperWidth: 0,
			imageWrapperWidth: 0,
			transitioning: false,
			textWrapperStyle: {},
			imageWrapperStyle: {},
			transitionDuration: 600,
			direction: '',
		}
	},
	methods: {
		setUp() {
			this.items = [];
			this.slides.map((item, index) => {
				item.index = index;
				item.active = (item.index === 0);
				this.items.push(item)
			});
			this.resetTransition();
			setTimeout(this.setSizes, 1000);
		},
		setSizes() {
			this.textWrapperWidth = !!this.$refs.textWrapper.offsetWidth ? this.$refs.textWrapper.offsetWidth : false;
			this.imageWrapperWidth = !!this.$refs.imageWrapper.offsetWidth ? this.$refs.imageWrapper.offsetWidth : false;
		},
		slideLeft() {
			this.transitioning = true;
			this.textWrapperStyle = {
				transform: 'translateX(' + this.textWrapperWidth + 'px)',
			}
			this.imageWrapperStyle = {
				transform: 'translateX(-' + this.imageWrapperWidth + 'px)',
			}
			this.activateSlide();
			setTimeout(this.transitionDoneLeft, this.transitionDuration);
		},
		transitionDoneLeft() {
			this.transitioning = false;
			const slide = this.items.shift();
			this.items.push(slide);
			this.resetTransition()
		},
		slideRight() {
			this.transitioning = true;
			this.textWrapperStyle = {
				transform: 'translateX(0)',
			}
			this.imageWrapperStyle = {
				transform: 'translateX(0)',
			}
			this.activateSlide();
			this.transitioning = false;
		},
		prepareSlideReverse() {
			const slide = this.items.pop();
			this.items.unshift(slide);
			this.textWrapperStyle = {
				transition: 'none',
				transform: `translateX(${this.textWrapperWidth}px)`
			};
			this.imageWrapperStyle = {
				transform: 'translateX(-' + this.imageWrapperWidth + 'px)',
				transition: 'none'
			}
		},
		resetTransition() {
			this.textWrapperStyle = {
				transition: 'none',
				transform: `translateX(0)`
			};
			this.imageWrapperStyle = {
				transition: 'none',
				transform: `translateX(0)`
			}
		},
		slide(direction) {
			if (!this.transitioning) {
				this.direction = direction;
				if (direction === 'right') {
					this.slideLeft();
				} else if (direction === 'left') {
					if (this.items.indexOf(this.whoIsActive) === -1) {
						this.prepareSlideReverse();
						setTimeout(this.slideRight, 10)
					} else {
						this.slideRight();
					}
				}
			}
		},
		activateSlide() {
			let currentIndex = this.items.indexOf(this.whoIsActive[0]),
					next = this.direction === 'right' ? currentIndex + 1 : currentIndex - 1;
			this.items.map((item, index) => {
				item.active = index === next
			})
		}
	},
	mounted() {
		this.setUp();
		window.addEventListener("resize", this.setSizes);
	},
	watch: {
		transitioning(oldVal, newVal) {
			// console.log(oldVal, 'watch old')
			// console.log(newVal, 'watch new')
		}
	}
}
</script>