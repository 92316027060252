import {createStore} from 'vuex';

export default createStore({
    modules: {},
    state: {
        scrollToOffset: 0,
        paths: {
            media: ''
        },
        modal: false,
        modalObj: {
            loadType: null,
            purgeOnClose: false,
            header: '',
            body: '',
            class: '',
            buttons: {},
            components: []
        },
        snackList: [],
        device: {
            mobile: false,
            width: 0,
            os: null
        },
        navStatus: false,
        offices: [],
        companies: [],
        baseUrl: '',
    },
    mutations: {
        setBaseUrl(state) {
            state.baseUrl = window.location.origin;
            if (state.baseUrl.includes('localhost')) {
                state.baseUrl = 'https://reunionventures.com';
            }
        },
        //modal
        showModal: function (state) {
            state.modal = true;
        },
        hideModal: function (state) {
            state.modal = false;
            if (state.modalObj.purgeOnClose) {
                let _this = this;
                setTimeout(function () {
                    _this.commit('unsetModal');
                }, 500);
            }
        },
        unsetModal: function (state) {
            state.modalObj = {
                loadType: null,
                purgeOnClose: false,
                header: '',
                body: '',
                class: '',
                buttons: {},
                components: []
            };
        },
        setModal: function (state, obj) {
            state.modalObj.loadType = obj.loadType;
            state.modalObj.purgeOnClose = obj.purgeOnClose;
            state.modalObj.header = obj.header;
            state.modalObj.body = obj.body;
            state.modalObj.class = obj.class;
            state.modalObj.component = obj.component;
            if (!!obj.buttons) {
                state.modalObj.buttons = obj.buttons;
            } else {
                state.modalObj.buttons = false;
            }
            if (obj.loadType == 'component') {
                state.modalObj.components = [];
                state.modalObj.components.push(obj.component.name);
            }
            this.commit('showModal');
        },
        //snack
        popSnack: function (state, snack) {
            for (var i = 0; i < state.snackList.length; i++) {
                if (state.snackList[i] == snack) {
                    state.snackList.splice(i, 1);
                }
            }
        },
        popLoadingSnack: function (state) {
            let search = state.snackList.filter(x => x.type == 'loading');
            if (search.length) {
                this.commit('popSnack', search[0]);
            }
        },
        pushSnack: function (state, snack) {
            if (snack.type == 'loading') {
                if (state.snackList.filter(x => x.type == 'loading').length) {
                    return;
                }
                snack.id = parseInt(Math.random() * 1000);
                state.snackList.push(snack);
                return snack;
            } else {
                snack.id = parseInt(Math.random() * 1000);
                state.snackList.push(snack);
                setTimeout(function (_this) {
                    _this.commit('popSnack', snack);
                }, 5000, this);
            }
        },
        //set device info
        setDevice: function (state, obj) {
            this.dispatch('isMobile').then(res => {
                state.device.mobile = res;
            })
            this.dispatch('findOS').then(os => {
                state.device.os = os;
            })
            state.device.width = window.innerWidth;
            if (state.device.width > 1000) {
                state.scrollToOffset = -120
            } else {
                state.scrollToOffset = 0
            }
        },
        //nav
        toggleNav: function (state) {
            state.navStatus = !state.navStatus;
        },
        hideNav: function (state) {
            state.navStatus = false;
        },

        toggleContactModal: function (state) {
            let modal = {
                loadType: 'component',
                component: {name: 'contact', options: {}},
                buttons: false,
                class: 'modal-1',
            };
            this.commit('setModal', modal);
        },

        fetchData(state) {
            // this.commit('fetchOffices')
            this.commit('fetchCompanies')
        },
        fetchOffices(state) {
            Axios({
                method: "Get",
                url: state.baseUrl + '/api/v1/content-manger/get-data/rv_offices',
            }).then(response => {
                state.offices = response.data.result;
            }).catch(error => {
                console.log(error)
            });
        },
        fetchCompanies(state) {
            Axios({
                method: "Get",
                url: state.baseUrl + '/api/v1/content-manger/get-data/rv_companies?sortBy=id&orderBy=asc',
            }).then(response => {
                let res = response.data.result;
                res.splice(2, 0, {empty: true});
                res.splice(6, 0, {empty: true});
                state.companies = res;
            }).catch(error => {
                console.log(error)
            });
        },
    },
    getters: {
        getScrollToOffset: state => {
            return state.scrollToOffset
        },
        getSnacks: state => {
            return state.snackList;
        },
        getModal: state => {
            return state.modalObj
        },
        getDevice: state => {
            return state.device
        },
        getMobile: state => {
            return state.device.mobile
        },
        getNav: state => {
            return state.navStatus
        },
        getPaths: state => {
            return state.paths
        },
        getOffices: state => {
            return state.offices
        },
        getCompanies: state => {
            return state.companies
        },
        getBaseUrl: state => {
            return state.baseUrl
        }
    },
    actions: {
        setUp({commit, state}) {
            commit('setBaseUrl');
            commit('fetchData');
            commit('setDevice');
            this.dispatch('scrollTop');
            window.addEventListener("resize", function (ev) {
                commit('hideNav');
                commit('setDevice');
            });
            this.dispatch('paths')
        },
        isMobile() {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
                    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
                return true;
            } else {
                return false;
            }
        },
        findOS() {
            let userAgent = window.navigator.userAgent,
                    platform = window.navigator.platform,
                    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
                    os = null;

            if (macosPlatforms.indexOf(platform) !== -1) {
                os = 'mac';
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                os = 'ios';
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                os = 'windows';
            } else if (/Android/.test(userAgent)) {
                os = 'android';
            } else if (!os && /Linux/.test(platform)) {
                os = 'linux';
            }

            return os;
        },

        scrollTop() {
            const debounce = (fn) => {
                let frame;
                return (...params) => {
                    if (frame) {
                        cancelAnimationFrame(frame);
                    }
                    frame = requestAnimationFrame(() => {
                        fn(...params);
                    });
                }
            };
            const storeScroll = () => {
                // document.documentElement.dataset.scroll = window.scrollY;
                // document.documentElement.dataset.scrolled = (window.scrollY > 200);
            }
            document.addEventListener('scroll', debounce(storeScroll), {passive: true});
            storeScroll();
        },
        paths({state}) {
            if (process.env.NODE_ENV === 'development') {
                state.paths.media = ''
            } else {
                state.paths.media = '/template'
            }
        }
    }
})
