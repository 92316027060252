<template>
	<div class="pitch-wrapper relative">
		<div class="pitch-intro" :class="introClass" v-show="intro">
			<div class="intro-text-crop">
				<div class="messages-cont">
					<p class="intro-1">Hi</p>
					<p class="intro-2">Welcome to our pitch</p>
				</div>
			</div>
		</div>
		<div class="pitch-steps" :class="[{ on: !intro }]">
			<form class="steps-wrapper" ref="form">
				<div class="steps-cont" ref="container">
					<div class="steps" :style="{ transform: 'translateX(' + translate + 'px)' }"
					     :class="['current-step-' + step]">
						<div class="step">
							<p class="q">1. Enter your full name please</p>
							<div class="input-cont">
								<input name="full_name" type="text" placeholder="Full Name" tabindex="-1"
								       @keyup.enter="changeStep('plus')"/>
								<div class="hint">press enter for next</div>
							</div>
						</div>
						<div class="step">
							<p class="q">2. what is your email?</p>
							<div class="input-cont">
								<input name="email" type="email" placeholder="Email Address" tabindex="-1"
								       @keyup.enter="changeStep('plus')"/>
								<div class="hint">press enter for next</div>
							</div>
						</div>
						<div class="step">
							<p class="q">3. what is your company name?</p>
							<div class="input-cont">
								<input name="company_name" type="text" placeholder="Company name" tabindex="-1"
								       @keyup.enter="changeStep('plus')"/>
								<div class="hint">press enter for next</div>
							</div>
						</div>
						<div class="step">
							<p class="q">4. what is your website address?</p>
							<div class="input-cont">
								<input name="website" type="text" placeholder="http://" tabindex="-1"
								       @keyup.enter="changeStep('plus')"/>
								<div class="hint">press enter for next</div>
							</div>
						</div>
						<div class="step">
							<p class="q">5. Tell us a brief about your company (100 words)</p>
							<div class="input-cont">
								<textarea name="company" @keyup.enter="changeStep('plus')" placeholder="Description"
								          tabindex="-1"/>
								<div class="hint">press enter for next</div>
							</div>
						</div>
						<div class="step">
							<p class="q">6. how much are you raising?</p>
							<div class="input-cont">
								<input name="raising" @keyup.enter="changeStep('plus')" type="text"
								       placeholder="type here" tabindex="-1"/>
								<div class="hint">press enter for next</div>
							</div>
						</div>
					</div>
				</div>
				<div class="step-footer">
					<div class="sending animate__animated trans-3" v-show="loading"
					     :class="[{ animate__fadeInLeft: loading }, { animate__fadeOutLeft: !loading }]">
						<span class="mr-1 fs-14 fw-700">Sending</span>
						<loading class="beige"></loading>
					</div>
					<div class="arrows-cont" v-show="!loading">
						<div class="arrow-left" :class="[{ 'op-5': step === 1 }]" @click="changeStep('minus')">
							<img :src="$store.getters.getPaths.media + '/img/icons/arrow-left.svg'"/>
						</div>
						<div class="arrow-right" :class="[{ 'op-5': step === stepMax }]" @click="changeStep('plus')">
							<img :src="$store.getters.getPaths.media + '/img/icons/arrow-right.svg'"/>
						</div>
					</div>
					<span @click="changeStep('plus')" class="btn beige">{{ step === stepMax ? "Send" : "Next" }}</span>
				</div>
			</form>
		</div>
		<div
				class="success-message animate__animated"
				:class="[{ animate__slideInRight: !loading && sent && success }, { animate__slideOutRight: loading || !sent || !success }]"
		>
			<p class="fs-32 lh-10 color-beige capital">congrats!</p>
			<p class="fs-24 lh-144 color-white mar-top-24">You Are in, We will call you Soon!</p>
		</div>
	</div>
</template>

<script>
import Loading from "@/js/components/loading";

export default {
	name: "Pitch",
	components: {Loading},
	data: () => {
		return {
			loading: false,
			success: false,
			sent: false,
			intro: true,
			introClass: null,
			step: 0,
			stepMax: 6,
			translateWidth: 0,
			translate: 0,
			form: null,
			fieldsMap: [
				{
					validations: ["required"],
					step: 1,
					name: "full_name",
					errorMessage: "Field required",
				},
				{
					validations: ["required", "email"],
					step: 2,
					name: "email",
					errorMessage: "Please enter a valid email",
				},
				{
					step: 3,
					name: "company_name",
					validations: ["required"],
					errorMessage: "Field required",
				},
				{
					step: 4,
					name: "website",
					validations: ["required", "url"],
					errorMessage: "Please enter a valid URL",
				},
				{
					step: 5,
					name: "company",
					validations: ["required"],
					errorMessage: "Field required",
				},
				{
					step: 6,
					name: "raising",
				},
			],
		};
	},
	methods: {
		setUp() {
			let tokenTag = document.querySelector('meta[name="csrf-token"]');
			this.token = !!tokenTag ? tokenTag.getAttribute('content') : null;
			this.form = this.$refs.form;
			setTimeout(this.startIntro, 1000);
			this.form = this.$refs.form;
		},
		focus() {
			let toFocus = this.fieldsMap.filter((x) => x.step == this.step)[0],
					element = this.form.elements[toFocus.name];
			element.focus({
				preventScroll: true,
			});
		},
		startIntro() {
			this.introClass = "start";
			setTimeout(this.removeIntro, 4500);
		},
		removeIntro() {
			this.intro = false;
			setTimeout(this.setWidth, 1000);
		},
		setWidth() {
			if (!!this.$refs.container.offsetWidth) {
				this.translateWidth = this.$refs.container.offsetWidth;
			} else {
				setTimeout(this.setWidth, 1000);
			}
		},
		validateEmail(email) {
			return String(email)
					.toLowerCase()
					.match(
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					);
		},
		validateUrl(url) {
			return String(url)
					.toLowerCase()
					.match(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/);
		},
		checkValidation(field) {
			let res = true,
					value = this.form.elements[field.name].value;
			if (!!field.validations && !!field.validations.length) {
				field.validations.forEach((validation) => {
					if (validation === "required") {
						if (!!this.form.elements[field.name]) {
							if (!!value) {
								this.clearMessage(field);
								res = true;
							} else {
								res = false;
								this.showMessage(field);
							}
						} else {
							// res = true
						}
					} else if (validation === "email") {
						if (this.validateEmail(value)) {
							res = true;
							this.clearMessage(field);
						} else {
							res = false;
							this.showMessage(field);
						}
					} else if (validation === "url") {
						if (this.validateUrl(value)) {
							res = true;
							this.clearMessage(field);
						} else {
							res = false;
							this.showMessage(field);
						}
					}
				});
			}
			return res;
		},
		showMessage(field) {
			let parent = this.form.elements[field.name].parentNode;
			parent.setAttribute("error", field.errorMessage);
		},
		clearMessage(field) {
			let parent = this.form.elements[field.name].parentNode;
			parent.removeAttribute("error");
		},
		checkStep() {
			let letPass = true,
					fields = this.fieldsMap.filter((x) => x.step == this.step);
			fields.map((field) => {
				letPass = letPass && this.checkValidation(field);
			});
			return letPass;
		},
		changeStep(type) {
			if (type === "minus") {
				if (this.step > 1) {
					this.step--;
					this.moveLeft();
				}
			} else if (type === "plus") {
				if (this.checkStep()) {
					console.log("checkStep");
					if (this.step < this.stepMax) {
						this.step++;
						this.moveRight();
					} else if (this.step === this.stepMax) {
						this.submit();
					}
				}
			}
		},
		moveLeft() {
			this.focus();
			this.translate += this.translateWidth;
		},
		moveRight() {
			this.focus();
			this.translate -= this.translateWidth;
		},
		prepareData() {
			let data = {}, items = this.form.elements;
			for (let item of items) {
				if (item.tagName === 'INPUT' || item.tagName === 'TEXTAREA') {
					data[item.name] = item.value
				}
			}
			return data;
		},
		submit() {
			if (this.loading) return false;
			let data = {
				data: this.prepareData()
			};
			this.loading = true;
			Axios({
				url: this.$store.getters.getBaseUrl + "/api/v1/content-manger/submit-response/rv_pitch",
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					'X-CSRF-TOKEN': this.token
				},
				data: JSON.stringify(data),
			})
					.then((res) => {
						console.log(res);
						this.loading = false;
						this.success = true;
						this.sent = true;
					})
					.catch((err) => {
						this.loading = false;
						this.sent = true;
						console.log(err);
					});
		},
	},
	mounted() {
		this.setUp();
		let _this = this;
		window.addEventListener("resize", function (ev) {
			_this.setWidth();
		});
	},
	watch: {
		intro() {
			this.step = 1;
			this.focus();
		},
	},
};
</script>
