<template>
	<div class="map-cont" v-if="whoIsActive">
		<div class="map" :ref="instanceId" style="width: 100%; height: 100%; min-height: 360px">
			<leaflet-map ref="map" :zoom="zoom" :center="[whoIsActive.lat, whoIsActive.lng]" :options="mapConfig">
				<leaflet-layer
						url="https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=75adce25-bc9d-486d-ba51-934c42204579"
						layer-type="base"
						name="OpenStreetMap"
				>
				</leaflet-layer>
				<leaflet-marker :lat-lng="[whoIsActive.lat, whoIsActive.lng]" :icon="iconOptions" ref="marker"
				                @ready="popNow($event, this)">
					<leaflet-popup ref="popUp" class="info-window-cont flex f-align-center back-beige">
						<div class="back-green" style="min-width: 240px">
							<h6 class="back-beige fs-12 fw-900 pad-all-12 color-green">Located Companies</h6>
							<div class="pad-all-12">
								<h5 v-for="(item, index) in locatedCompanies" :key="index"
								    class="color-beige fw-900 fs-12">♦ {{ item }}</h5>
							</div>
							<div class="address fs-14 fw-300 pad-right-12 color-white pad-left-12 pad-bot-12"
							     v-html="whoIsActive.address"></div>
						</div>
					</leaflet-popup>
				</leaflet-marker>
			</leaflet-map>
		</div>
	</div>
	<div class="offices-cont back-beige pad-all-48">
		<h2 class="fs-40 color-green fw-800 lh-10 mar-bot-24">Offices</h2>
		<ul class="list">
			<li
					v-for="(item, index) in items"
					:key="index"
					class="pad-top-12 pad-bot-12 pad-right-24 pad-left-24"
					:class="[{ active: item.active }]"
					@click="setActive(item)"
			>
				<div class="title-controls flex">
					<img class="w-20" :src="$store.getters.getPaths.media + '/img/icons/pin.svg'"/>
					<h6 class="fs-20 fw-800 color-green">{{ item.city }}</h6>
				</div>
				<p class="color-gray fs-16" v-html="item.address"></p>
			</li>
		</ul>
	</div>
</template>

<script>
import {icon} from "leaflet";

export default {
	name: "Offices",
	props: {
		offices: {
			type: Array,
			required: true,
			default: () => [],
		},
	},
	computed: {
		whoIsActive() {
			let search = this.items.filter((item) => item.active);
			return !!search ? search[0] : false;
		},
		locatedCompanies() {
			let str = this.whoIsActive.companies.split(',');
			return !!str ? str : [];
		}
	},
	data: () => {
		return {
			items: [],
			iconOptions: {},
			position: {
				lat: 52.3676,
				lng: 4.9041,
			},
			zoom: 19,
			mapConfig: {
				closePopupOnClick: false,
				doubleClickZoom: "center",
			},
			instanceId: "map__" + parseInt(Math.random() * 1000000),
		};
	},
	methods: {
		setActive(toBeActive) {
			this.items.map((item) => {
				item.active = item.index === toBeActive.index;
			});
			this.panTo();
		},
		panTo() {
			this.$refs.map.leafletObject.panTo(new L.LatLng(40.737, -73.923));
			this.$refs.map.leafletObject.panTo(new L.LatLng(this.whoIsActive.lat, this.whoIsActive.lng));
		},
		popNow(e, marker) {
			this.$refs.marker.leafletObject.openPopup();
		},
	},
	created() {
		this.items = [];
		this.offices.map((item, index) => {
			let itemCopy = item;
			item.index = index;
			this.items.push(itemCopy);
		});
	},
	mounted() {
		this.iconOptions = icon({
			iconUrl: "/template/img/icons/pin.svg",
			iconSize: [38, 48], // size of the icon
		});
	},
};
</script>
<style lang="scss">
.leaflet-popup-content-wrapper {
	padding: 0;
	background: transparent;
	//box-shadow: none;
	border-radius: 0;
}

.leaflet-popup-tip {
	padding: 0;
	background: transparent;
	//box-shadow: none;
	border-radius: 0;
}

.leaflet-popup-content {
	margin: 0 0 16px 0;
	width: auto !important;
}

.leaflet-popup-tip-container {
	display: none;
}

.leaflet-container a.leaflet-popup-close-button {
	margin: 10px;
}
</style>
